<template lang="pug">
.pane
  sidebar-title
    span {{ isFloatingImage ? $t('editFloatingImage') : $t('editImage') }}
  .sidebar-inner-box.pt-0
    sidebar-tab-wrapper(defaultTab="general")
      template(#content)
        sidebar-tab-content#general
          .border-top-h3.bt-0(:class="{ 'angle-close': showSource, 'angle-open': !showSource }")
            .h3-elem(@click="showSource = !showSource") {{ $t('imageSettings') }}
            transition(name="expand")
              .expand-transition(v-if="showSource")
                .sidebar-input-wrapper
                  DeviceSelector
                upload-area(
                  :src="backgroundSrc"
                  :editMobile="true"
                  @click="imageManager"
                  @remove="resetMobileImage"
                )
                om-range-input(
                  label="width"
                  :property="getPath('smartSize.width')"
                  :min="10"
                  :max="1000"
                  :step="5"
                  :editMobile="true"
                  @change="handleImageWidthChange"
                )
                om-dropdown-input(
                  v-if="selectedElement.type !== 'OmFloatingImage'"
                  label="align"
                  :property="getPath('align')"
                  :items="[ { key: 'left', value: 'left' }, { key: 'center', value: 'center' }, { key: 'right', value: 'right' }, ]"
                  :editMobile="true"
                )
          sidebar-accordion(:title="$t('redirectLink')")
            .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0 {{ $t('url') }}
            .pb-2
              om-simple-input(
                label=""
                property="data.redirectUrl"
                placeholder="https://www.example.com/"
              )
              om-switches(
                label="keepQueryParams"
                property="data.keepQueryParams"
                :enabledText="$t('yes')"
                :disabledText="$t('no')"
                tooltip="keepQueryParamsTooltip"
              )
              om-switches(
                label="openInNewTab"
                property="data.newTab"
                :enabledText="$t('yes')"
                :disabledText="$t('no')"
              )
              om-dropdown-input(label="reportAs" property="data.reportAs" :items="reportAs")
              .sidebar-help-wrapper.mt-4.ml-1
                .sidebar-help-content.smaller-size
                  | {{ $t(`reportAsTooltip.${selectedElement.data.reportAs}`) }}
              om-switches(
                label="buttonCampaignStatus"
                property="data.goalReached"
                :enabledText="$t('yes')"
                :disabledText="$t('no')"
              )
        slot(name="styleTabContent")
          sidebar-tab-content#style
            custom-theme-style
            om-border-box(propsProperty="selectedElement" typeOfComponent="image")
        advanced-tab(slot="advancedTabContent" :spacingProps="spacingProps")
</template>
<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import { get as _get } from 'lodash-es';
  import itemMixin from '@/editor/mixins/item';
  import { ELEMENTS } from '@/../../../libraries/template-properties/src/propertyHelper';
  import { getNameFromURL } from '@om/template-properties/src/imageReplace';

  export default {
    components: {
      DeviceSelector: () => import('@/editor/components/sidebar/components/DeviceSelector.vue'),
      UploadArea: () => import('@/editor/components/UploadArea.vue'),
      AdvancedTab: () =>
        import('@/editor/components/sidebar/tab/predefined/PredefinedAdvanced.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [itemMixin],
    data() {
      return {
        canEditMobile: true,
        show: false,
        showSource: true,
        showMobile: false,
        imagePositionType: [
          { key: 'Fill', value: 'full' },
          { key: 'Fit', value: 'contain' },
          { key: 'Span', value: 'cover' },
        ],
        options: {
          acceptedFileTypes: ['image/*'],
          url: 'http://localhost:3000/fileupload',
          clickable: false,
        },
      };
    },
    computed: {
      ...mapState(['selectedElement', 'images', 'mobilePreview', 'themeKit']),
      ...mapGetters(['nestedAccess', 'selectedImage', 'isEmbedded']),
      isFloatingImage() {
        return this.selectedElement.type === ELEMENTS.OmFloatingImage;
      },
      imagePosition() {
        return this.nestedAccess('selectedElement.desktop.background.imagePosition');
      },
      backgroundType() {
        return this.getValueOf('background.type');
      },
      backgroundUrl() {
        return this.getValueOf('background.imageUrl');
      },
      backgroundImage() {
        return this.getValueOf('background.imageId');
      },
      backgroundImageUrl() {
        const imageId = this.getValueOf('background.imageId');
        let url = _get(this.selectedImage, 'url');
        if (imageId) {
          const image = this.images.find((r) => r._id.toString() === imageId);
          url = _get(image, 'url');
        }
        return url;
      },
      sizeValue() {
        return this.getValueOf('smartSize.width');
      },
      hasMobileImage() {
        return this.getValueOf('mobile.background.imageId');
      },
      backgroundSrc() {
        return this.getValueOf('$device.background.imageUrl');
      },
      spacingProps() {
        const baseSpacingProps = {
          showPadding: false,
          typeOfComponent: 'image',
        };

        if (this.selectedElement.type === 'OmFloatingImage') {
          return { ...baseSpacingProps, showMargin: false };
        }

        return baseSpacingProps;
      },
      reportAs() {
        const options = [{ key: 'conversion', value: 'conversion' }];

        if (!this.isEmbedded) {
          options.push({ key: 'rejected', value: 'rejected' });
        }

        options.push({ key: 'none', value: 'none' });

        return options;
      },
      isLogoImage() {
        if (!this.themeKit?.logo?.current) return false;

        const logoName = getNameFromURL(this.themeKit.logo.current)
        const desktopUrl = this.selectedElement?.desktop?.background?.imageUrl;
        const mobileUrl = this.selectedElement?.mobile?.background?.imageUrl;
        const desktopName = getNameFromURL(desktopUrl);
        const mobileName = getNameFromURL(mobileUrl);

        return logoName === desktopName || logoName === mobileName;
      },
    },
    watch: {
      sizeValue() {
        this.setValueOf('smartSize.type', 'manual');
      },
    },

    methods: {
      ...mapMutations(['updateStyle', 'setImageManagerDestination', 'showImageManager', 'setImageManagerLogoUpload']),
      handleImageWidthChange() {
        const calcWidthInEm = this.getValueOf('mobile.calcWidthInEm');
        if (this.mobilePreview && !calcWidthInEm) {
          this.setValueOf('mobile.calcWidthInEm', true);
        }
      },
      triggerBrowse() {
        this.$refs.uploader.triggerBrowseFiles();
      },
      uploadSuccess(e, resp) {
        this.setValueOf('background.url', resp.url);
        this.setValueOf('background.type', 'image');
      },
      resetMobileImage() {
        const desktopImageId = this.getValueOf('desktop.background.imageId');
        if (!desktopImageId) {
          this.$bus.$emit('removeElement', { uid: this.selectedElement.uid });
        } else {
          this.setValueOf('mobile.background.imageId', null, true);
        }
      },
      imageManager() {
        this.setImageManagerDestination({
          targetAttr: 'selectedElement',
          addElement: false,
          mobileAlternative: false,
        });
        if (this.isLogoImage) this.setImageManagerLogoUpload(true);
        this.showImageManager();
      },
    },
  };
</script>